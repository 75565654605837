import React, { useEffect, useState, useRef, useContext } from "react";
import { Avatar } from "@material-ui/core";
import Chatbox from "./chatbox";
import "./style.css";
import * as actions from "./../../actions/customer";
import * as tenants from "./../../actions/tenant";
import * as msgs from "./../../actions/msg";
import * as rooms from "./../../actions/room";
import * as customers from "./../../actions/customer";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  ChatBubble,
  ArrowRightAltSharp,
  CallMade,
  MoreVert,
  AddCircleOutline,
  FilterListSharp,
  CheckOutlined
} from "@material-ui/icons";
import { TableVirtuoso, Virtuoso } from "react-virtuoso";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import { socket } from "../../context/socket";
import { isMobile } from "react-device-detect";
import moment from "moment";
import NotificationSound from "./../../images/notification-sound.mp3";
import FormDialogAddCustomer from "./FormDialogAddCustomer";
import { AuthContext } from "../../context/AuthContext";

const Inbox = ({ ...props }) => {
  let isTabinactive = false;
  const { getAuthUser } = useContext(AuthContext)
  const authUser = getAuthUser()
  const audioPlayer = useRef(null);

  const [page, setPage] = useState(1);
  const [custpage, setCustpage] = useState(1);
  const [roomsperpage, setroomsperpage] = useState(30);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [contact, setContact] = useState({});
  const [chatrooms, setChatrooms] = useState([]);
  const [isusersmodal, setisusersmodal] = useState(false);
  const [currRoom, setcurrRoom] = useState({});
  const [phone_number, setPhone_number] = useState("");
  const chatroomsRef = React.useRef(chatrooms);
  const [searchtext, setsearchtext] = useState("");
  const [ischatboxactive, setischatboxactive] = useState(false);
  const [isTabinact, setisTabinact] = useState(null);
  const [filter, setFilter] = useState("all");

  const onVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      isTabinactive = false;
      document.title = "Agents Portal";
      setisTabinact(false);
      // props.fetchRoomsPagination(
      //   1,
      //   roomsperpage,
      //   phone_number,
      // )
      console.log("Tab reopened, refetch the data!");
    } else {
      console.log("Tab closed, refetch the data!");
      isTabinactive = true;
      setisTabinact(true);
    }
  };

  useEffect(() => {
    console.log("isTabinact", isTabinact);
    if (isTabinact === false) refreshrooms();
  }, [isTabinact]);

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    props.fetchPagination(1, rowsPerPage);
    props.fetchAllTenants();

    getproductlist("");
    const msgupdateEvent = value => {
      console.log("isTabinactive", value);
      if (isTabinactive) {
        document.title = "🔔 New message";
        audioPlayer.current.play();
      }

      if (chatroomsRef.current.some(e => e.id === value.id)) {
        setChatrooms(
          chatroomsRef.current.map(x =>
            x.id === value.id
              ? {
                  ...x,
                  lastmsg: value.lastmsg,
                  isnew: value.isnew,
                  iscampaign: value.iscampaign,
                  updatedAt: value.updatedAt,
                  isagent: value.isagent,
                }
              : x,
          ),
        );
      } else {
        setChatrooms(previous => [value, ...previous]);
        // chatroomsRef.current  = [value, ...chatrooms]
      }
    };

    socket.on("roomlistupdate", msgupdateEvent);

    return () => {
      socket.off("roomlistupdate", msgupdateEvent);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    chatroomsRef.current = chatrooms;
    // console.log('chatrooms1',chatrooms)
  }, [chatrooms]);

  const getproductlist = searchkey => {
    if (props?.currentTenantnumber?.catalog_id) {
      props.fetchProducts(
        props?.currentTenantnumber?.catalog_id,
        "Bearer " + props.currentTenantnumber?.access_token,
        searchkey,
      );
    }
  };

  useEffect(() => {
    if (props?.currentTenantnumber?.phone_number?.length > 0) {
      socket.emit(
        "switchPhonenumber",
        props?.currentTenantnumber?.phone_number,
        phone_number,
      );
      setContact({});
      setPage(1);
      setChatrooms([]);
      setFilter('all')
      props.fetchRoomsPagination(
        1,
        roomsperpage,
        props?.currentTenantnumber?.phone_number,
        '',
        'all'
      );
      props.fetchTemplates(
        props?.currentTenantnumber?.wa_business_id,
        "Bearer " + props.currentTenantnumber?.access_token,
      );
      setPhone_number(props?.currentTenantnumber?.phone_number);
      getproductlist("");
    }
  }, [props.currentTenantnumber]);

  useEffect(() => {
    setChatrooms(props.rooms);
    chatroomsRef.current = props.rooms;
  }, [props.rooms]);

  const handleChangePage = () => {
    console.log("rooms end");
    if (page < props.metaRoom.totalPages + 1) {
      setPage(page + 1);
      props.fetchRoomsPagination(
        page + 1,
        roomsperpage,
        props?.currentTenantnumber?.phone_number,
        '',
        filter
      );
    }
  };
  const handleChangeCustomers = () => {
    console.log("rooms end1");

    setPage(custpage + 1);
    props.fetchCustomets(custpage + 1, 30);
  };
  const refreshrooms = () => {
    console.log("phone_number", phone_number);
    props.fetchRoomsPagination(1, roomsperpage, phone_number,'',
        filter);
  };

  const openContact = data => {
    // console.log(data)
    setContact(data?.customer);
    setcurrRoom(data);
    setischatboxactive(true);
  };

  const createRoom = data => {
    let roomobj = {
      customer: data.mobile,
      business_mobile: props.currentTenantnumber.phone_number,
      lastmsg: "",
    };
    const onSuccess = () => {
      props.fetchRoomsPagination(1, 50, props.currentTenantnumber.phone_number, '',
        filter);

      toast.success(data.firstname + " " + data.mobile + " added to inbox.");
    };
    props.creatRoom(roomobj, onSuccess);
  };
  const updateRoom = param => {
    const onSuccess = data => {
      toast.success("Chat transferred to BOT");
    };

    props.updateRoom(currRoom._id, param, onSuccess);
  };

  useEffect(() => {
    if (searchtext.length > 0) {
      setPage(0);
      props.fetchRoomsPagination(
        1,
        roomsperpage,
        props?.currentTenantnumber?.phone_number,
        searchtext,
        filter
      );
    }
  }, [searchtext]);

  useEffect(() => {

      setPage(1);
      props.fetchRoomsPagination(
        1,
        roomsperpage,
        props?.currentTenantnumber?.phone_number,
        searchtext,
        filter
      );
    
  }, [filter]);

  return (
    <section className="message-area">
      <div className="row">
        <div className="col-12">
          {props?.currentTenantnumber?.phone_number?.length > 0 ? (
            <div
              className={
                "chat-area " + (ischatboxactive ? "chatboxactive" : "")
              }
            >
              {/* chatlist */}
              <div className="chatlist">
                <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="chat-header">
                      <div className="msg-search">
                        <input
                          type="search"
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Search number.."
                          value={searchtext}
                          onChange={e => setsearchtext(e.target.value)}
                        />

                        <ul className="moreoption p-0 m-0">
                          <li className="navbar nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle ms-2"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <AddCircleOutline
                                color="action"
                              />
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <FormDialogAddCustomer
                                  create={props.createCustomer}
                                  currentTenantnumber={
                                    props.currentTenantnumber
                                  }
                                  createRoom={createRoom}
                                />
                              </li>
                            {(authUser.role==='superadmin' || authUser.role==='admin') && <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => setisusersmodal(!isusersmodal)}
                                >
                                  Add to chatroom
                                </a>
                              </li>}
                           
                            </ul>
                          </li>
                          <li className="navbar nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle px-2"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FilterListSharp
                                color="action"
                              />
                            </a>
                            <ul className="dropdown-menu">
                            <li className={filter==='all'?"bg-lightgray":''}>
                                <a
                                  className="dropdown-item"
                                  onClick={() => setFilter('all')}
                                >
                                  All 
                                </a>
                              </li>
                              <li className={filter==='new'?"bg-lightgray":''}>
                                <a
                                  className="dropdown-item"
                                  onClick={() => setFilter('new')}
                                >
                                  New
                                </a>
                              </li>

                              <li className={filter==='campaign'?"bg-lightgray":''}>
                                <a
                                  className="dropdown-item"
                                  onClick={() => setFilter('campaign')}
                                >
                                  Campaign
                                </a>
                              </li>
                           
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="modal-body mt-2">
                      {/* chat-list */}
                      <div className="chat-lists">
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="Open"
                            role="tabpanel"
                            aria-labelledby="Open-tab"
                          >
                            {/* chat-list */}
                            <div className="chat-list">
                              {/* {props.customers.map((data, index) =>
                                                            <a className={"d-flex align-items-center " + (contact.mobile === data.mobile ? "bg-lightgray" : "")}
                                                                onClick={() => openContact(data)}
                                                                key={index}
                                                            >
                                                                <div className="flex-shrink-0">
                                                                 
                                                                    <Avatar >{data.firstname?.[0]}</Avatar>

                                                                    <span className="active" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h3>{data.firstname}</h3>
                                                                    <p>{data.mobile}</p>
                                                                </div>
                                                            </a>
                                                        )} */}
                              <div className="contactlist">
                                {chatrooms.length > 0 && (
                                  <Virtuoso
                                    style={{ height: "76.3vh" }}
                                    data={chatrooms}
                                    endReached={handleChangePage}
                                    itemContent={(index, data) => (
                                      <a
                                        className={
                                          "d-flex align-items-center " +
                                          (contact.mobile ===
                                          data?.customer?.mobile
                                            ? "bg-lightgray"
                                            : "")
                                        }
                                        onClick={() => openContact(data)}
                                        key={index}
                                      >
                                        <div className="flex-shrink-0">
                                          <Avatar>
                                            {data?.customer?.firstname?.[0]}
                                          </Avatar>

                                          {/* <span className="active" /> */}
                                        </div>

                                        <div className="flex-grow-1 ms-2">
                                          <p>
                                            {data?.customer?.firstname}{" "}
                                            {data?.customer?.mobile}
                                          </p>
                                          <small className="text-muted">
                                            {data?.lastmsg?.replace(
                                              /<[^>]*>/g,
                                              " ",
                                            )}
                                            ..
                                          </small>

                                          {data?.isnew && (
                                            <span
                                              class="badge bg-danger fw-light float-end ms-1"
                                              style={{ fontSize: 9 }}
                                            >
                                              New
                                            </span>
                                          )}
                                          {data?.iscampaign && (
                                            <span
                                              class="badge bg-success fw-light float-end ml-2"
                                              style={{ fontSize: 9 }}
                                            >
                                              Campaign
                                            </span>
                                          )}
                                          {data?.isagent === "yes" && (
                                            <span
                                              class="badge bg-primary fw-light float-end"
                                              style={{ fontSize: 9 }}
                                            >
                                              Agent
                                            </span>
                                          )}
                                          <div
                                            className="small d-block text-success"
                                            style={{ fontSize: 10 }}
                                          >
                                            {moment(data?.updatedAt).format(
                                              "h:mm a, Do MMM",
                                            )}
                                          </div>
                                        </div>
                                      </a>
                                    )}
                                  />
                                )}
                              </div>
                              {/* {chatrooms.map((data, index) =>
                                                            <a className={"d-flex align-items-center " + (contact.mobile === data.customer.mobile ? "bg-lightgray" : "")}
                                                                onClick={() => openContact(data.customer)}
                                                                key={index}
                                                            >
                                                                <div className="flex-shrink-0">

                                                                    <Avatar >{data.customer.firstname?.[0]}</Avatar>

                                                                 
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">

                                                                    <p>{data.customer.firstname} {data.customer.mobile}</p>
                                                                    <small className="text-muted">{data.lastmsg.replace(/<[^>]*>/g, ' ')}..</small>

                                                                    {data.isnew && <span class="badge bg-danger fw-light float-end" style={{ fontSize: 10 }}>New</span>}
                                                                    {data.iscampaign && <span class="badge bg-success fw-light float-end ml-2" style={{ fontSize: 10 }}>Campaign</span>}

                                                                </div>
                                                            </a>
                                                        )} */}
                            </div>
                            {/* chat-list */}
                          </div>
                        </div>
                      </div>
                      {/* chat-list */}
                    </div>
                  </div>
                </div>
              </div>
              {/* chatlist */}
              {/* chatbox */}
              <div className="chatbox">
                {contact?.mobile ? (
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="msg-head">
                        <div className="row">
                          <div className="col-10">
                            <div className="d-flex align-items-center">
                              <span
                                className="chat-icon"
                                onClick={() => setischatboxactive(false)}
                              >
                                <img
                                  className="img-fluid"
                                  src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                  alt="image title"
                                />
                              </span>
                              <div className="flex-shrink-0">
                                <Avatar>{contact.firstname?.[0]}</Avatar>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h3 className="text-capitalize">
                                  {contact.firstname}
                                </h3>
                                <p>{contact.mobile}</p>
                              </div>
                            </div>
                          </div>
                          {currRoom.isagent === "yes" && (
                            <div className="col-2 mt-1">
                              <ul className="moreoption">
                                <li className="navbar nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <MoreVert />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          updateRoom({ isagent: "no", socketroom: contact.mobile+props.currentTenantnumber.phone_number_id })
                                        }
                                      >
                                        Enable BOT
                                      </a>
                                    </li>
                                    {/* <li>
                                                                <a className="dropdown-item" >
                                                                    Edit Label
                                                                </a>
                                                            </li> */}
                                    {/* <li>
                                                                <hr className="dropdown-divider" />
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item">
                                                                    Something else here
                                                                </a>
                                                            </li> */}
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <Chatbox
                        contact={contact}
                        templates={props.templates}
                        currentTenantnumber={props.currentTenantnumber}
                        products={props.products}
                        getproductlist={getproductlist}
                      />
                    </div>
                  </div>
                ) : (
                  <div class="row h-100">
                    <div class="col d-flex align-items-center justify-content-center">
                      <p class="text-center h5">Select contact to start chat</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div class="row h-100">
              <div class="col d-flex align-items-center justify-content-center mt-5">
                <p class="text-center h5 mt-5">Select business number </p>
                <p class="text-center h5 mt-5">
                  <CallMade size={30} style={{ marginTop: -50 }} />
                </p>
              </div>
            </div>
          )}
          {/* chatbox */}
        </div>
      </div>

      <Dialog
        open={isusersmodal}
        onClose={() => setisusersmodal(!isusersmodal)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">Contacts</DialogTitle>
        <DialogContent>
          <div className="contactlist">
            <TableVirtuoso
              style={{ height: 400 }}
              data={props.customers}
              itemContent={(index, user) => (
                <>
                  <td className="border-bottom py-2 w-50">
                    {user.firstname} {user.lastname}
                  </td>
                  <td className="border-bottom py-2 w-50">{user.mobile}</td>
                  <td className="border-bottom py-2 w-50">
                    {user?.labels?.map(e => (
                      <span className="badge bg-success me-1 fw-light">
                        {e}
                      </span>
                    ))}
                  </td>
                  <td className="border-bottom py-2 w-50">
                    {" "}
                    <Checkbox onChange={() => createRoom(user)} />
                  </td>
                </>
              )}
              endReached={handleChangeCustomers}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setisusersmodal(!isusersmodal)}
            color="primary"
          >
            Cancel
          </Button>
          {/* <Button onClick={() => null} color="primary" autoFocus>
                        Send
                    </Button> */}
        </DialogActions>
      </Dialog>

      <audio ref={audioPlayer} src={NotificationSound} />
    </section>
  );
};

const mapStateToProps = state => ({
  customers: state.customer.customers,
  meta: state.customer.metaCustomer,
  tenants: state.tenant.tenants,
  templates: state.msg.msgstemplates,
  currentTenantnumber: state.tenantnumber.currentTenantnumber,
  rooms: state.room.rooms,
  metaRoom: state.room.metaRoom,
  products: state.msg.products,
});

const mapActionToProps = {
  fetchPagination: actions.Pagination,
  fetchAllTenants: tenants.fetchAll,
  fetchTemplates: msgs.fetchTemplates,
  fetchRoomsPagination: rooms.Pagination,
  creatRoom: rooms.create,
  updateRoom: rooms.findidpdate,
  fetchProducts: msgs.fetchProducts,
  createCustomer: customers.create,
  fetchCustomets: customers.virtualscroll,
};

export default connect(mapStateToProps, mapActionToProps)(Inbox);
