import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import moment from "moment";
import CustomerTable from "../formDialog/CustomerTable";
import API from "../../../utils/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  name: "",
  template: "",
  schedule_type: "instant",
  schedule_time: "",
  isdraft: false,
  customers: [],
  business_mobile: '',
  token: '',
  phone_number_id: '',
  audience_selection_type: 'labels',
  labels: []
}

const FormDialogAddCampaign = (props) => {
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [currentTemplate, setcurrentTemplate] = useState({});
  const [schedule_type, setschedule_type] = React.useState('instant');
  const [tempheaderimage, settempheaderimage] = useState('');
  const [loader, setloader] = useState(false);

  const handleClickOpen = () => {
    setErrors({});
    setCampaign(initialFormState);
    setOpen(true);
    setcurrentTemplate({})
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setCampaign({ ...campaign, [name]: value })
  }

  const getcustomersslection = val => {
    console.log('getcustomersslection', Object?.values(val)?.flat())
    setCampaign({ ...campaign, ['customers']: Object?.values(val)?.flat() })

  }

  useEffect(() => {
    setCampaign({ ...campaign, ['template']: currentTemplate.name })


  }, [currentTemplate])


  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;
    if (!campaign.name || campaign.name.trim() === "") {
      toast.success('Name can not be empty');

      formIsValid = false;
      tempErrors["name"] = "Cannot be empty";
      setloader(false)
      return
    }
    if (!campaign.labels.length && campaign.audience_selection_type === 'labels') {
      toast.warn('Audience labels can not be empty');
      setloader(false)
      return
    }
    if (!campaign.customers.length && campaign.audience_selection_type === 'users') {
      toast.warn('Audience can not be empty');
      setloader(false)
      return
    }

    if (!campaign.template || campaign.template.trim() === "") {
      toast.warn('Please choose template to send');
      setloader(false)
      return
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    setloader(true)
    let format = currentTemplate?.components?.[0].format?.toLowerCase()
    console.log('format', format)
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
      setloader(false)
    }
    e.preventDefault();
    if (validate()) {
      if ((format === 'image' || format === 'video' || format === 'document') && tempheaderimage.length < 2) {
        toast.error(format + ' can not be empty.')
        setloader(false)
        return
      }
      let msgobj = {
        from: "",
        timestamp: moment().format('x'),
        usertype: 'agent', //bot/agent
        ack: '',
        phone_number_id: props?.currentTenantnumber?.phone_number_id,
        display_phone_number: props?.currentTenantnumber?.phone_number,
        token: props?.currentTenantnumber?.access_token,
        type: 'template',
        template: {
          "name": currentTemplate?.name,
          "language": { "code": currentTemplate.language },
          "components": tempheaderimage.length && format ? [
            {
              "type": "header",
              "parameters": [
                {
                  "type": format,
                  [format]: {
                    "link": tempheaderimage
                  }
                }

              ]
            }
          ] : []
        },
        body: currentTemplate?.components?.map(e => e.text).join('<br><br>')
      }


      let camp = { ...campaign, business_mobile: props.currentTenantnumber.phone_number, phone_number_id: props.currentTenantnumber.phone_number_id, token: props.currentTenantnumber.access_token, msgobj: msgobj, tenantnumberid:props.currentTenantnumber.id }


      props.create(camp, onSuccess)
    }
  }
  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 15360) {
      toast.warning('maximum size allowed:images 5mb / video 15mb');
      setloader(false)
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        settempheaderimage(process.env.REACT_APP_IMG_URL + res.data.data.path)
      })

  }
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        disablePortal
      // sx={{
      //   '.MuiPaper-root': {
      //     padding: 0,
      //     margin:0,
      //     width: '100%',
      //     maxHeight: '100%',
      //     height: '100%'
      //   },
      // }}
      >
        {/* <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>New Campaign</DialogTitle> */}

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

          <div className="row">
            <div className="col-lg-12 col-12">
              <TextField
                variant="outlined"
                name="name"
                label="Name"
                value={campaign.name}
                fullWidth
                onChange={handleInputChange}
                {...(errors.mobile && { error: true, helperText: errors.mobile })}
                style={{ marginBottom: 25 }}
              />
            </div>

          </div>



          <div className="row">
            <div className="col-lg-12 col-12">
              <div class="accordion" id="accordionExample">

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Choose audience
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body ">

                      <div className="row">
                        <div className="col-lg-3 col-12">
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="audience_selection_type" name="audience_selection_type" value={campaign.audience_selection_type} onChange={handleInputChange}>
                              <FormControlLabel value="labels" control={<Radio />} label="Via labels" />
                              <FormControlLabel value="users" control={<Radio />} label="One by one" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="col-lg-9 col-12">
                          {campaign.audience_selection_type === 'labels' &&
                            <>
                              <FormControl
                                fullWidth={true}
                              >
                                <InputLabel id="demo-simple-select-label">Choose labels</InputLabel>
                                <Select
                                  name="labels"
                                  label="Choose Labels"
                                  value={campaign.labels}
                                  onChange={handleInputChange}
                                  multiple
                                >
                                  {props.labels.map(item => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                  ))}

                                </Select>
                              </FormControl>
                            </>}
                        </div>
                      </div>



                      {campaign.audience_selection_type === 'users' && <CustomerTable getcustomersslection={getcustomersslection} />
                      }


                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Choose preapproved Template
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='row'>
                        <div className='col-lg-4 col-12'>
                          <ul class="list-group">
                            {props.templates.map(item => (
                              <a className={`list-group-item ${currentTemplate.name === item.name ? 'bg-lightgray' : ""}`} onClick={() => setcurrentTemplate(item)}>{item.name}</a>
                            ))}
                          </ul>
                        </div>
                        <div className='col-lg-8 col-12 py-2' style={{ background: '#d9fdd2', borderRadius: 5, minHeight: 200 }}>
                          {currentTemplate?.components?.map(item => (

                            item.type === 'HEADER' &&
                            <> {item.format === 'IMAGE' &&
                              <>
                                {tempheaderimage.length > 2 && <img src={tempheaderimage || 'https://placehold.co/400x100' || item.example.header_handle[0]} className='w-100' />}

                                <input type="text" value={tempheaderimage} class="form-control form-control mt-2" placeholder="Enter Image Url" onChange={(e) => settempheaderimage(e.target.value)} />

                                <p className='my-2 text-center'>Or</p>

                                <input
                                  type="file"
                                  name="file"
                                  label="Choose Image "
                                  accept=".png,.jpg,.gif"
                                  onChange={handleInputChangeForFileType}
                                  id="images"
                                  className='w-100 p-0 border-0'
                                />
                                  <small>max file size allowed: 5mb</small>
                              </>
                              ||
                              item.format === 'VIDEO' &&
                              <>

                                {tempheaderimage.length > 2 && <video className='w-100' height="240" src={tempheaderimage || 'https://placehold.co/400x100' || item.example.header_handle[0]} />}
                                <input type="text" value={tempheaderimage} class="form-control form-control mt-2" placeholder="Enter video Url" onChange={(e) => settempheaderimage(e.target.value)} />

                                <p className='my-2 text-center'>Or</p>

                                <input
                                  type="file"
                                  name="file"
                                  label="Choose Video (mp4 only)"
                                  accept=".mp4"
                                  onChange={handleInputChangeForFileType}
                                  id="images"
                                  className='w-100 p-0 border-0'
                                />
                                                                  <small>max file size allowed: 15mb</small>

                              </>
                              ||
                              item.format === 'DOCUMENT' &&
                              <>
                                {tempheaderimage.length > 2 && <embed src={tempheaderimage || 'https://placehold.co/400x100' || item.example.header_handle[0]} className='w-100' height="240" />}
                                <input type="text" value={tempheaderimage} class="form-control form-control mt-2" placeholder="Enter Document Url" onChange={(e) => settempheaderimage(e.target.value)} />

                                <p className='my-2 text-center'>Or</p>

                                <input
                                  type="file"
                                  name="file"
                                  label="Choose Document (pdf only)"
                                  accept=".pdf"
                                  onChange={handleInputChangeForFileType}
                                  id="images"
                                  className='w-100 p-0 border-0'
                                />
                              </>
                            }
                              {item.format === 'TEXT' &&
                                <>
                                  <p className='mt-2'>{item.text}</p>
                                </>
                              }
                            </>

                            || item.type === 'BODY'
                            && <p className='mt-2'>{item.text}</p>

                            || item.type === 'FOOTER'
                            && <p className='small'>{item.text}</p>

                            || item.type === "BUTTONS"
                            && <>{item?.buttons?.map(btn => (
                              <a className='mb-2' href={btn.url} target='blank'>{btn.text}</a>
                            ))}</>
                          ))}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Schedule your campaign
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="schedule_type" value={campaign.schedule_type} onChange={handleInputChange}>
                          <FormControlLabel value="instant" control={<Radio />} label="Instant" />
                          <FormControlLabel value="schedule" control={<Radio />} label="Schedule" disabled />

                        </RadioGroup>
                      </FormControl>

                      {campaign.schedule_type === "schedule" && <form noValidate>
                        <br />
                        <TextField
                          id="datetime-local"
                          label="Date & Time"
                          type="datetime-local"
                          defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </form>}
                    </div>
                  </div>
                </div>
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="heading4">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                      Set your campaign live
                    </button>
                  </h2>
                  <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                    <div class="accordion-body">

                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-12">

            </div>
          </div>


        </DialogContent>


        <DialogActions style={{ padding: 30 }}>
          {loader?
          <>
          <div class="spinner-border text-success spinner-border-sm me-3" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> Please wait, campaign is processing...
          </>
          
          :
          <>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
          </>}
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddCampaign;