import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_IMG_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export default {
  auth(url = 'users') {
    return {
      login: ({ email, password }) => http.post(url + '/authenticate', {
        username: email,
        password: password
      }),
      register: ({ email, name, password }) => http.post(url + '/register', { email, name, password })
    }
  },

  user(url = 'users') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, firstName = null, email = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&firstName=" + firstName + "&email=" + email, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/register', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  customer(url = 'customers') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, mobile = null, firstname = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&mobile=" + mobile + "&firstname=" + firstname, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      bulkupload: newRecord => http.post(url + '/bulkupload', newRecord, config),
      exportcsv: param => http.post(url + '/exportcsv', param, config),

    }
  },
  label(url = 'labels') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  campaign(url = 'campaigns') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, business_mobile = null, name = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&business_mobile=" + business_mobile + "&name=" + name, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      getAnalytics: filter => http.post(url + '/getAnalytics', filter, config),

    }
  },
  tenantnumber(url = 'tenantnumbers') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, firstName = null, email = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&firstName=" + firstName + "&email=" + email, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  tenant(url = 'tenants') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };
   

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, email = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name + "&email=" + email, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  room(url = 'rooms') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 50, business_mobile = null, customer =null, filter= null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&business_mobile=" + business_mobile+ "&customer=" + customer+ "&filter=" + filter, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (updatedRecord) => http.put(url + "/update/" +  updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      findidpdate: (id, updatedRecord) => http.post(url + "/findidpdate/" + id, updatedRecord, config),
    }
  },
  msg(url = 'msgs') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };
 
    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, mobile = null, phone_number_id = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&mobile=" + mobile + "&phone_number_id=" + phone_number_id, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      sendwsmsg: newRecord => http.post(url + '/sendwsmsg', newRecord, config),
      sendbotmsg: newRecord => http.post(url + '/bot_sendmsg', newRecord, config),
      gettemplates: (wa_business_id, token) => http.get('https://graph.facebook.com/v19.0/'+wa_business_id+'/message_templates?fields=name,status,components,language&limit=10', {
        headers: {
          'authorization': token
        }
      }),
      getproducts: (catalog_id, token, searchkeyword) => http.get('https://graph.facebook.com/v19.0/'+catalog_id+'/products?filter={name: {i_contains: "' + searchkeyword + '"}}', {
        headers: {
          'authorization': token
        }
      }),
      sendmedia:(file, msgsParam) => http.post(url + '/sendmedia', file,msgsParam, config),
      getAnalytics: filter => http.post(url + '/getAnalytics', filter, config),

    }
  },

  
  utility(url = 'utility') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      upload: (file) => http.post(url + '/upload', file, config),
    }
  },
  notification() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "key=AAAAM2TkNWk:APA91bET4-uzLsF2s_ola_KDhz4e_k0eJ4UZaF_U-DHWoC5d4d9DhSREGDoNE4eLl182OtQBvggUslpGycli1IzlASN2z0NF-rTmnX7iK7TMWOMyHG4yoUvJz3HB34EQYpMz0yVpHgoA"
      }
    };

    return {
      sendPushNotification: (notificationParam) => http.post('https://fcm.googleapis.com/fcm/send', notificationParam, config),
    }
  },

}